import * as React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { adsRequest } from '../../utils/ads-request';
import { AdvertisementSlot } from '../../components/AdvertisementSlot';
import { useAppContext } from '../../contexts/AppContext';
import { LAYOUTADSCONFIGRATION_AC, LAYOUTADSCONFIGRATION_AC_BOTTOM } from '../../types/static';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCursorContext } from '../../contexts/CursorContext';

const SectionRight = styled('div')(({ theme }) => ({
  width: 300, // different
  marginTop: theme.spacing(7),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  '@media screen and (max-width: 1220px)': {
    display: 'none',
  },
}));

const AdvertisementSlotTop = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
}));

const AdvertisementSlotBottom = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

const AdvertisementTop = () => {
  return <AdvertisementSlotTop configuration={LAYOUTADSCONFIGRATION_AC} areaName="aLayoutRightTopAds" elevation={1} slotIdentifier="idhb-right-300" variant="300x600" />
}

const AdvertisementBottom = () => {
  return <AdvertisementSlotBottom configuration={LAYOUTADSCONFIGRATION_AC_BOTTOM} areaName="aLayoutRightTopAds" elevation={1} slotIdentifier="idhb-right-small-300" variant="300x600" />
}

export const LayoutAds = () => {
  const router = useRouter();
  const appContext = useAppContext();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { componentOptions } = appContext.useConfig();
  const { panel } = useCursorContext();
  // {xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536}

  const layoutAdsElements: Record<string, boolean> = {
    'idhb-right-300': !(componentOptions.component_layout_right_ads_disabled || componentOptions.component_layout_right_tablet_ads_disabled || componentOptions.component_layout_right_ads_mobile_disabled),
    'idhb-right-small-300': !(componentOptions.component_layout_right_middle_ads_disabled || componentOptions.component_layout_right_middle_tablet_ads_disabled || componentOptions.component_layout_right_middle_mobile_ads_disabled),
  };

  const adsList = Object.keys(layoutAdsElements).filter(key => layoutAdsElements[key]);

  React.useEffect(() => {
    adsRequest(adsList);
  }, []);


  React.useEffect(() => {
    const { events } = router;
    const handleRouteChange = () => {
      if (adsList.length > 0 && !componentOptions.component_ads_disabled) {
        adsRequest(adsList);
      }
    };
  
    events.on('routeChangeComplete', handleRouteChange);
    events.on('hashChangeComplete', handleRouteChange);
  
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
      events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router]);

  

  return (
    panel ? (
      <SectionRight>
        <AdvertisementTop />
        <AdvertisementBottom />
      </SectionRight>
    ) : (
      !componentOptions.component_ads_disabled && adsList.length > 0) && (
      <SectionRight>
        {(lg && !componentOptions.component_layout_right_ads_disabled) && <AdvertisementTop /> }
        {(isBetweenMdAndLg && !componentOptions.component_layout_right_tablet_ads_disabled) && <AdvertisementTop /> }
        {(mdDown && !componentOptions.component_layout_right_ads_mobile_disabled) && <AdvertisementTop /> }

        {(lg && !componentOptions.component_layout_right_middle_ads_disabled) && <AdvertisementBottom /> }
        {(isBetweenMdAndLg && !componentOptions.component_layout_right_middle_tablet_ads_disabled) && <AdvertisementBottom /> }
        {(mdDown && !componentOptions.component_layout_right_middle_mobile_ads_disabled) && <AdvertisementBottom /> }
      </SectionRight>
    )
  );
};
