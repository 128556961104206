import type { LayoutProps } from '../../types';
import * as React from 'react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import { LayoutAds } from '../LayoutC/LayoutAds';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: '100%',
  width: '100%',
  padding: theme.spacing(0, 4), // different
  '@media screen and (max-width: 800px)': {
    padding: theme.spacing(0, 2),
  },
})) as typeof Container;

const SectionLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'auto',
  margin: theme.spacing(0, 'auto'), // different
}));


export const LayoutC = (props: LayoutProps) => {
  const { children } = props;

  return (
    <Root>
      <SectionLeft>{children}</SectionLeft>
      <LayoutAds />
    </Root>
  );
};
